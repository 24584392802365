<!-- =========================================================================================
File Name: RegisterJWT.vue
Description: Register Page for JWT
----------------------------------------------------------------------------------------
Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div
    class="h-screen flex w-full bg-img vx-row no-gutter items-center justify-center"
  >
    <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 sm:m-0 m-4">
      <vx-card>
        <div slot="no-body" class="full-page-bg-color">
          <div class="vx-row no-gutter">
            <div
              class="vx-col hidden sm:hidden md:hidden lg:block lg:w-1/2 mx-auto self-center"
            >
              <img
                src="@/assets/images/pages/register.jpg"
                alt="register"
                class="mx-auto"
              />
            </div>
            <div
              class="vx-col sm:w-full md:w-full lg:w-1/2 mx-auto self-center d-theme-dark-bg"
            >
              <div class="px-8 pt-8 register-tabs-container">
                <div class="vx-card__title mb-4">
                  <h4 class="mb-4">{{ $t("CreateAccount") }}</h4>
                  <p>{{ $t("CreateAccountMessage") }}</p>
                </div>
                <div class="clearfix">
                  <vs-input
                    v-validate="'required|min:3'"
                    data-vv-validate-on="blur"
                    :label-placeholder="$t('Name')"
                    name="name"
                    :placeholder="$t('Name')"
                    v-model="name"
                    type="text"
                    class="w-full"
                  />
                  <span class="text-danger text-sm">{{
                    errors.first("name")
                  }}</span>

                  <vs-input
                    name="email"
                    type="email"
                    label-placeholder="Email"
                    placeholder="Email"
                    :value="email"
                    class="w-full mt-6"
                    disabled
                  />
                  <span class="text-danger text-sm">{{
                    errors.first("email")
                  }}</span>

                  <vue-tel-input
                    class="w-full mt-6"
                    v-model="internationalPhone"
                    @validate="validatePhone"
                    name="phone"
                    id="phone"
                  />

                  <vs-input
                    ref="password"
                    type="password"
                    data-vv-validate-on="blur"
                    v-validate="'required|min:6|max:20'"
                    name="password"
                    :label-placeholder="$t('Password')"
                    :placeholder="$t('Password')"
                    v-model="password"
                    class="w-full mt-6"
                  />
                  <span class="text-danger text-sm">{{
                    errors.first("password")
                  }}</span>

                  <vs-input
                    type="password"
                    v-validate="'confirmed:password'"
                    data-vv-validate-on="blur"
                    data-vv-as="password"
                    name="confirm_password"
                    :label-placeholder="$t('ConfirmPassword')"
                    :placeholder="$t('ConfirmPassword')"
                    v-model="confirm_password"
                    class="w-full mt-6"
                  />
                  <span class="text-danger text-sm">{{
                    errors.first("confirm_password")
                  }}</span>

                  <vs-checkbox
                    class="vx-col w-full mt-4 h-full"
                    v-model="checkPrivacy"
                  >
                    <div class="w-96">
                      <div class="flex">
                        <small>
                          {{ $t("AgreeWithPolicy") }}
                          <a
                            href="https://www.duotalk.io/termos-de-uso/"
                            target="_blank"
                            >{{ $t("TermsOfService") }}</a
                          >
                          {{ $t("And") }}
                          <a
                            href="https://www.duotalk.io/politica-de-privacidade/"
                            target="_blank"
                            >{{ $t("PrivacyPolicy") }}</a
                          >.
                        </small>
                      </div>
                    </div>
                  </vs-checkbox>

                  <vs-button
                    class="float-right mt-12"
                    @click="registerUserJWt"
                    :disabled="!validateForm"
                    >{{ $t("Register") }}</vs-button
                  >
                  <p class="clear-both">
                    &nbsp;
                    <br />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      checkPrivacy: false,
      internationalPhone: "",
      validPhoneNumber: false,
      name: "",
      email: "",
      password: "",
      confirm_password: "",
      isTermsConditionAccepted: true,
    };
  },
  computed: {
    validateForm() {
      return (
        !this.errors.any() &&
        this.name != "" &&
        this.email != "" &&
        this.password != "" &&
        this.confirm_password != "" &&
        this.validPhoneNumber &&
        this.checkPrivacy &&
        this.isTermsConditionAccepted === true
      );
    },
  },
  methods: {
    validatePhone(value) {
      this.validPhoneNumber = !!value.valid;
    },
    registerUserJWt() {
      // If form is not validated or user is already login return
      if (!this.validateForm)
        this.$vs.notify({
          title: this.$t("Error"),
          text: this.$t("FormValidationGeneric"),
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
          position: "top-right",
          time: 4000,
        });

      const payload = {
        userDetails: {
          invatation_id: this.$route.params.invatation_id,
          name: this.name,
          email: this.email,
          password: this.password,
          phone: this.internationalPhone,
        },
        notify: this.$vs.notify,
      };
      this.$store
        .dispatch("auth/registerUserInvatationJWT", payload)
        .then(() => {
          const loginpayload = {
            remember_me: false,
            userDetails: {
              email: this.email,
              password: this.password,
              phone: this.internationalPhone,
            },
          };
          this.$store
            .dispatch("auth/loginJWT", loginpayload)
            .then((response) => {
              this.$store
                .dispatch("acc/setCurrentAccount", {
                  acc: response.data.data[0]._id,
                })
                .then(() => {
                  this.$acl.change(this.$store.state.user.userRole);
                  this.$vs.loading.close();
                  this.$router.push(this.$router.currentRoute.query.to || "/");
                });
            });
          this.$vs.notify({
            title: this.$t("Welcome"),
            text: this.$t("RegisteredSuccessfully"),
            iconPack: "feather",
            icon: "icon-success-circle",
            color: "success",
            position: "top-right",
            time: 4000,
          });
        });
    },
  },
  mounted() {
    if (typeof this.$route.params.invatation_id !== "undefined") {
      this.$store
        .dispatch("auth/fetchInvitedUser", {
          id: this.$route.params.invatation_id,
        })
        .then((result) => {
          if (result.email != null) this.email = result.email;
          else {
            this.$vs.notify({
              title: this.$t("Error"),
              text: this.$t("UnexpectedError"),
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger",
              position: "top-right",
              time: 4000,
            });
            this.$router.push(this.$router.currentRoute.query.to || "/login");
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: this.$t("Error"),
            text: error.message || error.data[0].msg,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
            position: "top-right",
            time: 4000,
          });
        });
    } else {
      this.$vs.loading.close();
      this.$vs.notify({
        title: this.$t("Error"),
        text: this.$t("UnexpectedError"),
        iconPack: "feather",
        icon: "icon-alert-circle",
        color: "danger",
        position: "top-right",
        time: 4000,
      });
    }
  },
};
</script>

<style src="vue-tel-input/dist/vue-tel-input.css"></style>
